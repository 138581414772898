import './shared';
import './modules/front';
import React from 'react';
import ReactDOM from 'react-dom';
import ItemDatePicker from './modules/front/ItemDatePicker';
import OrderItemPackageButton from './modules/front/OrderItemPackageButton';

// Bootstrap
import './modules/front/scss/main.scss'
//import 'bootstrap/scss/bootstrap.scss';


// Webpack HMR

if (module.hot) {
	module.hot.accept();
}

document.addEventListener('DOMContentLoaded', () => {
	const e = React.createElement;
	const domContainer = document.querySelectorAll('.item_date_picker_container');

	for (let i = 0; i < domContainer.length; i++) {
		const root = ReactDOM.createRoot(domContainer[i]);
		root.render(React.createElement(ItemDatePicker, {
			'locale': domContainer[i].getAttribute('data-locale'),
			'seasonId': domContainer[i].getAttribute('data-season-id'),
			'offices': JSON.parse(domContainer[i].getAttribute('data-offices')),
			'office': domContainer[i].getAttribute('data-office'),
			'lockOffice': domContainer[i].getAttribute('data-lock-office'),
			'length': domContainer[i].getAttribute('data-length'),
			'startDay': domContainer[i].getAttribute('data-start-day'),
		}));

	}

	const orderItemPackageButton = document.querySelectorAll('.component-order-item-package-button');
	for (let i = 0; i < orderItemPackageButton.length; i++) {
		const root = ReactDOM.createRoot(orderItemPackageButton[i]);
		root.render(React.createElement(OrderItemPackageButton, {
			'locale': orderItemPackageButton[i].getAttribute('data-locale'),
			'packageId': orderItemPackageButton[i].getAttribute('data-package-id'),
			'lengthHours' : orderItemPackageButton[i].getAttribute('data-length-hours'),
			'offices': JSON.parse(orderItemPackageButton[i].getAttribute('data-offices')),
			'office': orderItemPackageButton[i].getAttribute('data-office'),
			'lockOffice': orderItemPackageButton[i].getAttribute('data-lock-office'),
		}));
	}
});



